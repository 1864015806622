<template>
  <div class="card card-custom h-100 bg-light-primary">
    <!-- begin::Loading -->
    <template v-if="isMounted === false || isLoading">
      <div class="loading-container loading-overlap">
        <div class="loading-backdrop bg-dark" />
        <div class="loading-block">
          <div class="blockui ml-auto mr-auto w-100">
            <span class="float-left">Gathering data...</span>
            <span class="spinner spinner-primary ml-auto" />
          </div>
        </div>
      </div>
    </template>
    <!-- end::Loading -->

    <!-- begin::Error -->
    <template v-else-if="isErrorActive">
      <div class="loading-container loading-overlap">
        <div class="loading-backdrop bg-dark" />
        <div class="loading-block w-100 px-10">
          <error-translation-alert
            ref="errorAlert"
            :error="activeError"

            class="container"
          />
        </div>
      </div>
    </template>
    <!-- end::Error -->

    <div class="card-body d-flex flex-column p-0">
      <div class="d-flex align-items-center justify-content-between card-spacer  my-auto">
        <div class="d-flex flex-column mr-2">
          <span class="text-white font-weight-bold font-size-h5">Total sessions</span>
          <span class="text-light font-weight-bold mt-2">Total sessions of sunbed</span>
        </div>
        <span class="symbol symbol-primary symbol-45">
          <span class="symbol-label font-weight-bolder font-size-h6 min-w-45px w-auto px-3">{{ data && data.sessions && data.sessions.total || 0 }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { errorComponentMixin } from '@vedicium/core-vue';

import Equipment from '@/libs/classes/equipment';

import errorTranslationAlert from '@/components/errors/translation.alert.vue';

export default {
  mixins: [errorComponentMixin],
  props: {
    equipment: {
      type: Equipment,
      default: null,
    },
  },
  data () {
    return {
      isLoading: false,
      isMounted: false,

      data: null,
      timezone: this.equipment.location.timezone || 'UTC',
    };
  },
  components: {
    errorTranslationAlert,
  },

  async mounted () {
    try {
      await this.getChartData();
    } catch (e) {
      console.error(e);
    }

    await this.$nextTick();

    this.$set(this, 'isMounted', true);
  },

  methods: {
    async getChartData () {
      this.$set(this, 'isLoading', true);
      this.$set(this, 'data', []);

      try {
        this.$set(this, 'data', await this.$ws.get(`${Equipment.uri}/statistics/key-figures`, {
          query: {
            equipment: (this.equipment ? this.equipment._meta.guid : ''),
            offset: this.timezone,
          },
        }));
      } catch (e) {
        this.$errors.handle(e, { component: this, ui_element: false });
        console.error(e);
        throw e;
      } finally {
        this.$set(this, 'isLoading', false);
      }

      return this.data;
    },
  },
};
</script>
