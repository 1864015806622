<template>
  <div class="container">
    <div class="row row-cols-1 row-cols-lg-2">
      <div class="col mb-3 mb-lg-0">
        <total-sessions-card
          ref="totalSessionsCard"
          class="min-h-175px"

          :equipment="equipment"
        />
      </div>

      <div class="col mb-3 mb-lg-0">
        <last-week-sessions-card
          ref="lastWeekSessionsCard"

          :equipment="equipment"

          class="min-h-175px h-100"
        />
      </div>
    </div>
  </div>
</template>

<script>
import equipmentMixin from '@/components/pages/sunbeds/view/libs/mixin';

import totalSessionsCard from './total-sessions.card.vue';
import lastWeekSessionsCard from './last-week-sessions.card.vue';

export default {
  mixins: [equipmentMixin],
  components: {
    totalSessionsCard,
    lastWeekSessionsCard,
  },
};
</script>
