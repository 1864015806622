<template>
  <div class="card card-custom h-100">
    <!-- begin::Loading -->
    <template v-if="isMounted === false || isLoading">
      <div class="loading-container loading-overlap">
        <div class="loading-backdrop bg-dark" />
        <div class="loading-block">
          <div class="blockui ml-auto mr-auto w-100">
            <span class="float-left">Gathering data...</span>
            <span class="spinner spinner-primary ml-auto" />
          </div>
        </div>
      </div>
    </template>
    <!-- end::Loading -->

    <div class="card-body d-flex flex-column p-0">
      <div class="d-flex align-items-center justify-content-between card-spacer">
        <div class="d-flex flex-column mr-2">
          <span class="text-dark font-weight-bold font-size-h5">Last week sessions</span>
          <span class="text-muted font-weight-bold mt-2">{{ range.from.format('LL') }} - {{ range.to.format('LL') }}</span>
        </div>
        <span class="symbol symbol-light-primary symbol-45">
          <span class="symbol-label font-weight-bolder font-size-h6 min-w-45px w-auto px-3">{{ data.total || 0 }}</span>
        </span>
      </div>

      <apexchart
        class="card-rounded-bottom mt-auto h-75"
        :type="chart.type"
        :height="chart.height"
        :options="chart.options"
        :series="chart.series"
      />
    </div>
  </div>
</template>

<script>
import ApexCharts from 'vue-apexcharts';
import moment from 'moment-timezone';
import Equipment from '@/libs/classes/equipment';

export default {
  props: {
    equipment: {
      type: Equipment,
      default: null,
    },
  },
  components: {
    apexchart: ApexCharts,
  },
  data () {
    const lastISOWeek = moment.tz(this.equipment.location.timezone || 'UTC').subtract(1, 'isoWeek');
    const range = {
      from: lastISOWeek.clone().startOf('isoWeek'),
      to: lastISOWeek.clone().endOf('isoWeek'),
    };

    return {
      isLoading: false,
      isMounted: false,

      range,
      timezone: this.equipment.location.timezone || 'UTC',
      data: [],
      chart: {
        type: 'area',
        height: 75,

        series: [],

        options: {
          colors: [this.$metronic.config.layout.get('colors.theme.base.primary')],
          chart: {
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {},
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          fill: {
            type: 'solid',
            opacity: [0.5],
            colors: [this.$metronic.config.layout.get('colors.theme.light.primary')],
          },
          stroke: {
            curve: 'smooth',
            show: true,
            width: 3,
            colors: [this.$metronic.config.layout.get('colors.theme.base.primary')],
          },
          xaxis: {
            type: 'datetime',
            min: range.from.valueOf(),
            max: range.to.clone().startOf('day').valueOf(),
            lines: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
              datetimeUTC: false,
              style: {
                colors: this.$metronic.config.layout.get('colors.gray.gray-500'),
                fontSize: '12px',
                fontFamily: this.$metronic.config.layout.get('font-family'),
              },
            },
            crosshairs: {
              show: false,
              position: 'front',
              stroke: {
                color: this.$metronic.config.layout.get('colors.gray.gray-500'),
                width: 1,
                dashArray: 3,
              },
            },
            tooltip: {
              enabled: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
            labels: {
              show: false,
              style: {
                colors: this.$metronic.config.layout.get('colors.gray.gray-500'),
                fontSize: '12px',
                fontFamily: this.$metronic.config.layout.get('font-family'),
              },
            },
          },
          markers: {
            colors: [this.$metronic.config.layout.get('colors.theme.light.primary')],
            strokeColor: [this.$metronic.config.layout.get('colors.theme.base.primary')],
            strokeWidth: 3,
          },
        },
      },
    };
  },

  async mounted () {
    try {
      await this.getChartData();
      this.configureChartData(this.data ? this.data.results : []);
    } catch (e) {
      console.error(e);
    }

    await this.$nextTick();

    this.$set(this, 'isMounted', true);
  },

  methods: {
    async getChartData () {
      this.$set(this, 'isLoading', true);
      this.$set(this, 'data', []);

      try {
        this.$set(this, 'data', await this.$ws.get(`${Equipment.uri}/statistics/sessions/daily/${this.range.from.valueOf()}/${this.range.to.valueOf()}`, {
          query: {
            equipment: (this.equipment ? this.equipment._meta.guid : ''),
            offset: this.timezone,
          },
        }));
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        this.$set(this, 'isLoading', false);
      }

      return this.data;
    },

    async configureChartData (results = null) {
      if (!results || Array.isArray(results) === false) {
        this.$set(this.chart.series, []);
        return;
      }

      const series = [{
        name: 'Sessions',
        data: [],
      }];

      // Loop through range
      const rangeLoop = {
        start: this.range.from.clone(),
        end: this.range.to.clone(),
      };

      do {
        const date = rangeLoop.start.clone();
        series[0].data.push([
          date.valueOf(),
          results.filter((result) => date.isSame(result.date, 'date'), []).map((result) => result.total, 0).reduce((a, b) => a + b, 0),
        ]);
      } while (rangeLoop.start.add(1, 'day').diff(rangeLoop.end) < 0);
      this.$set(this.chart, 'series', series);
    },
  },
};
</script>
